import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';  // Ensure your store is properly configured
import './assets/multiselect.css';  // Correct CSS import
import './assets/tailwind.css';


// FontAwesome setup
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTrash, faFileExport);

// If you have Firebase setup
import { app as firebaseApp } from './firebaseConfig';

// Create the Vue app
const app = createApp(App);

// Register the FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use the router and store
app.use(router);
app.use(store);

// Mount the app to the DOM
app.mount('#app');
